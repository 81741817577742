@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    #index-bulletin {
        .bulletin-section {
            .bulletin-section-holder {
                .plaque-links-block {
                    margin: 0 0 20px;
                }
            }
        }
    }
}
