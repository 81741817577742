@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .about-text { 
        margin: 0 0 15px;
        .about-text-holder { 
            background-color: transparent;
            padding: 0;
            h2, .h2 {

            }

            .image {
                .image-holder { 
                    img {

                    }

                    .desc {
                        font-size: 12px;
                        line-height: 18px;
                        padding: 19px 21px 21px;
                    }
                }
            }

            .text {
                &.col-2 {
                    column-gap: 20px;
                }

                p {
                    line-height: 129%;
                    &:not(:last-of-type) {
                        margin: 0 0 14px;
                    }
                }
            }

            .action {
                margin: 11px 0 0;
            }
        }
    }

    .articles-list-block { 
        .articles-list-block-holder { 
            padding: 33px 30px 25px;
            .articles-list { 
                .articles-list-holder { 
                    margin-bottom: -11.5px;
                    .article-link-preview { 
                        margin: 0 0 11.5px;
                        .article-link-preview-holder { 
                            .article-link { 
                                padding: 18px 25px 23px;
                                .desc { 
                                    height: 17px;
                                    margin: 0 0 10px;
                                    .important { 
                                        font-size: 10px;
                                        line-height: 10px;
                                        padding: 3.5px 7px;
                                    }

                                    .date {
                                        font-size: 12px;
                                        height: 12px;
                                    }
                                }

                                .name {
                                    line-height: 140%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
