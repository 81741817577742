@media (max-width: $screen-sm) {
    // sm-view
    .main-banner-section {
        height: 100vh;
        height: var(--app-height);
        .main-banner-section-holder { 
            .main-banner-bkg-slider { 

            }

            .main-banner-content-slider-block { 
                .main-banner-content-slider-block-holder { 
                    .main-banner-content-slider { 

                        .nav {
                            ul.slick-dots {
                                bottom: 5px;
                            }
                        }

                        .main-banner-content-slider-holder { 
                            .main-banner-content-slide { 
                                padding: 80px 0 40px;
                                .main-banner-content-slide-holder { 
                                    padding: 5px;
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    .text { 
                                        width: 100%;
                                        padding: 15px;
                                        align-items: start;
                                        .text-holder { 
                                            width: 100%;
                                            .desc {
                                                
                                                .heading {
                                                    height: 44px;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    display: -moz-box;
                                                    -moz-box-orient: vertical;
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;
                                                    line-clamp: 2;
                                                    box-orient: vertical;
                                                }
                                            .inner-text {
                                                height: 105px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -moz-box;
                                                -moz-box-orient: vertical;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 5;
                                                -webkit-box-orient: vertical;
                                                line-clamp: 5;
                                                box-orient: vertical;
                                                p {
                                                    font-size: 14px;
                                                    line-height: 20px;
                                                   
                                                }
                                            }
                                               
                                            }

                                            .action { 
                                                margin: 9px 0 0;
                                                .btn {
                                                    width: 100%;
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }

                                    .image { 
                                        width: 100%;
                                        flex-grow: 1;
                                        flex-shrink: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
