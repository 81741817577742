.about-section {
  display: inline-block;
  width: 100%;
}
.about-section .about-section-holder {
  display: inline-block;
  width: 100%;
}

.about-text {
  display: inline-block;
  width: 100%;
}
.about-text .about-text-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 40px;
}
.about-text .about-text-holder h2, .about-text .about-text-holder .h2 {
  margin: 0 0 30px;
}
.about-text .about-text-holder .image {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.about-text .about-text-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.about-text .about-text-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.about-text .about-text-holder .image .image-holder .desc {
  position: absolute;
  z-index: 3;
  right: -1px;
  top: -1px;
  font-size: 14px;
  line-height: 20px;
  background-color: #F8F8F8;
  padding: 18px 20px 21px;
  overflow: hidden;
  border-radius: 0 0 0 20px;
}
.about-text .about-text-holder .text {
  display: inline-block;
  width: 100%;
  text-align: justify;
}
.about-text .about-text-holder .text.col-2 {
  column-count: 2;
  column-gap: 40px;
}
.about-text .about-text-holder .text ul li:not(:last-of-type) {
  margin: 0 0 15px;
}
.about-text .about-text-holder .action {
  display: inline-block;
  width: 100%;
  margin: 26px 0 0;
}
.articles-list-block {
  display: inline-block;
  width: 100%;
}
.articles-list-block .articles-list-block-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 30px;
}
.articles-list-block .articles-list-block-holder .articles-list {
  display: inline-block;
  width: 100%;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder {
  display: inline-block;
  width: 100%;
  margin-bottom: -9.5px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview {
  display: inline-block;
  width: 100%;
  margin: 0 0 9.5px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder {
  display: inline-block;
  width: 100%;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link {
  display: inline-block;
  width: 100%;
  background-color: #F8F8F8;
  color: #1D1D1B;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px 30px 30px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link:hover .name {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc {
  display: flex;
  align-items: center;
  height: 26px;
  margin: 0 0 10px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .important {
  margin-right: 10px;
  background-color: #056256;
  color: white;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 7px;
  border-radius: 100px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .date {
  font-size: 14px;
  line-height: 14px;
}
.articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .name {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  font-weight: 400;
  color: #1D1D1B;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}

@media (max-width: 1279px) {
  .about-text {
    margin: 0 0 15px;
  }
  .about-text .about-text-holder {
    background-color: transparent;
    padding: 0;
  }
  .about-text .about-text-holder .image .image-holder .desc {
    font-size: 12px;
    line-height: 18px;
    padding: 19px 21px 21px;
  }
  .about-text .about-text-holder .text.col-2 {
    column-gap: 20px;
  }
  .about-text .about-text-holder .text p {
    line-height: 129%;
  }
  .about-text .about-text-holder .text p:not(:last-of-type) {
    margin: 0 0 14px;
  }
  .about-text .about-text-holder .action {
    margin: 11px 0 0;
  }
  .articles-list-block .articles-list-block-holder {
    padding: 33px 30px 25px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder {
    margin-bottom: -11.5px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview {
    margin: 0 0 11.5px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link {
    padding: 18px 25px 23px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc {
    height: 17px;
    margin: 0 0 10px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .important {
    font-size: 10px;
    line-height: 10px;
    padding: 3.5px 7px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .date {
    font-size: 12px;
    height: 12px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .name {
    line-height: 140%;
  }
}
@media (max-width: 767px) {
  .about-text .about-text-holder .image {
    margin: 0 0 18px;
  }
  .about-text .about-text-holder .image .image-holder .desc {
    font-size: 12px;
    line-height: 17px;
    padding: 11px 28px 10px 15px;
  }
  .about-text .about-text-holder .text.col-2 {
    column-count: 1;
    column-gap: 0px;
  }
  .about-text .about-text-holder .text p {
    line-height: 20px;
  }
  .about-text .about-text-holder .action {
    margin: 23px 0 0;
  }
  .about-text .about-text-holder .action .btn {
    width: 100%;
    text-align: center;
  }
  .articles-list-block .articles-list-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
    padding: 35px 5.175% 20px;
    margin-bottom: -4px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder {
    margin-bottom: -7.5px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview {
    margin: 0 0 7.5px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link {
    padding: 16px 20px 15px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc {
    margin: 0 0 7px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .important {
    font-size: 12px;
    line-height: 12px;
    padding: 4.5px 7px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .desc .date {
    font-size: 12px;
    line-height: 12px;
  }
  .articles-list-block .articles-list-block-holder .articles-list .articles-list-holder .article-link-preview .article-link-preview-holder .article-link .name {
    font-size: 16px;
    line-height: 22px;
  }
}
#index-bulletin .bulletin-section .bulletin-section-holder .plaque-links-block {
  margin: 0 0 30px;
}

@media (max-width: 1279px) {
  #index-bulletin .bulletin-section .bulletin-section-holder .plaque-links-block {
    margin: 0 0 20px;
  }
}
@media (max-width: 767px) {
  #index-bulletin .bulletin-section .bulletin-section-holder .plaque-links-block {
    margin: 0 0 30px;
  }
}
#links .links-section .iconed-links-block {
  margin: 0 0 42px;
}

@media (max-width: 767px) {
  #links .links-section .iconed-links-block {
    margin: 0 0 31px;
  }
}
.main-banner-section {
  display: inline-block;
  width: 100%;
  height: 800px;
}
.main-banner-section .main-banner-section-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.main-banner-section .main-banner-section-holder .main-banner-bkg-slider {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.main-banner-section .main-banner-section-holder .main-banner-bkg-slider .main-banner-bkg-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-bkg-slider .main-banner-bkg-slider-holder .main-banner-img {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-bkg-slider .main-banner-bkg-slider-holder .main-banner-img .main-banner-img-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-bkg-slider .main-banner-bkg-slider-holder .main-banner-img .main-banner-img-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .wrapper-inside {
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .prev, .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .next {
  left: -60px;
  transform: translateY(calc(-50% + 28px));
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .next {
  left: auto;
  right: -60px;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .nav ul.slick-dots {
  bottom: 30px;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 150px 0 94px;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid white;
  box-sizing: border-box;
  padding: 40px;
  position: relative;
  overflow: hidden;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: 1;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text {
  width: calc(100% - 660px);
  height: auto;
  display: flex;
  align-items: center;
  padding: 0 19px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder {
  display: inline-block;
  width: 80%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc {
  display: inline-block;
  width: 100%;
  color: white;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .heading {
  margin: 0 0 19px;
  display: inline-block;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .inner-text {
  display: inline-block;
  width: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .action {
  display: inline-block;
  width: 100%;
  margin: 27px 0 0;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image {
  width: 660px;
  height: auto;
  position: relative;
  z-index: 2;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
}

@media (max-width: 1470px) {
  .main-banner-section {
    height: 690px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .prev, .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .next {
    left: -15px;
    background-color: white;
    color: #056256;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .prev:hover, .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .next:hover {
    background-color: #056256;
    border-color: #056256;
    color: white;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .next {
    left: auto;
    right: -15px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text {
    width: calc(100% - 460px);
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image {
    width: 460px;
  }
}
@media (max-width: 1279px) {
  .main-banner-section {
    height: 490px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .nav ul.slick-dots {
    bottom: 9px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide {
    padding: 85px 0 45px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder {
    padding: 30px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text {
    width: calc(100% - 332px);
    padding: 0;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder {
    width: 94%;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .heading {
    margin: 0 0 14px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc p {
    font-size: 12px;
    line-height: 140%;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .action {
    margin: 7px 0 0;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image {
    width: 332px;
  }
}
@media (max-width: 767px) {
  .main-banner-section {
    height: 100vh;
    height: var(--app-height);
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .nav ul.slick-dots {
    bottom: 5px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide {
    padding: 80px 0 40px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder {
    padding: 5px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text {
    width: 100%;
    padding: 15px;
    align-items: start;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder {
    width: 100%;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .heading {
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .inner-text {
    height: 105px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    line-clamp: 5;
    box-orient: vertical;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .desc .inner-text p {
    font-size: 14px;
    line-height: 20px;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .action {
    margin: 9px 0 0;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .text .text-holder .action .btn {
    width: 100%;
    text-align: center;
  }
  .main-banner-section .main-banner-section-holder .main-banner-content-slider-block .main-banner-content-slider-block-holder .main-banner-content-slider .main-banner-content-slider-holder .main-banner-content-slide .main-banner-content-slide-holder .image {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.banner {
  display: inline-block;
  width: 100%;
}
.banner .banner-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.banner .banner-holder a {
  display: inline-block;
  width: 100%;
}
.banner .banner-holder a img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.banner-slider .nav ul.slick-dots {
  bottom: 40px;
}
@media (max-width: 1279px) {
  .banner-slider .nav ul.slick-dots {
    bottom: 5px;
  }
}
@media (max-width: 767px) {
  .banner-slider .nav ul.slick-dots {
    bottom: 0;
  }
}
.bulletin-section {
  display: inline-block;
  width: 100%;
}
.bulletin-section .bulletin-section-holder {
  display: inline-block;
  width: 100%;
}

.bulletin-tabs-block {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder {
  display: inline-block;
  width: 100%;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 26px 30px 30px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls {
  display: inline-block;
  width: 100%;
  margin: 0 0 10px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
  margin: 0 -5px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
  float: left;
  margin: 0 4px 10px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  padding: 11.5px 20px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a.current {
  background-color: #056256;
  border-color: #056256;
  color: white;
  pointer-events: none;
  touch-action: none;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs .tab {
  display: inline-block;
  width: 100%;
}

.inner-tab {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .iconed-links-block {
  margin: 0 0 20px;
}
.inner-tab .inner-tab-holder .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
  padding: 23px 26px 26px;
}
.inner-tab .inner-tab-holder .link-buttons-block {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 38px 40px 40px;
  overflow: hidden;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
  display: inline-block;
  width: 100%;
  color: #666666;
  font-weight: 400;
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links {
  display: inline-block;
  width: calc(100% + 15px);
  margin-bottom: -16px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
  float: left;
  margin-right: 12px;
  margin-bottom: 16px;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  font-weight: 400;
  padding: 10px 25px;
  font-size: 18px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}
.inner-tab .inner-tab-holder .iframe-block {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  padding: 80px 30px;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder .inner-holder {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder .inner-holder > img {
  max-width: 100%;
}
.inner-tab .inner-tab-holder > .text.variant-2 {
  padding: 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 ul, .inner-tab .inner-tab-holder > .text.variant-2 ol {
  margin-top: 17px;
  padding-left: 25px;
  margin-bottom: 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text.variant-2 ol li:not(:last-of-type) {
  margin: 0 0 11px;
}
.inner-tab .inner-tab-holder > .text {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 37px 40px 40px;
  background-color: white;
  position: relative;
  z-index: 2;
}
.inner-tab .inner-tab-holder > .text .image {
  padding-bottom: 30px;
}
.inner-tab .inner-tab-holder > .text .image.left {
  float: left;
  padding-right: 30px;
}
.inner-tab .inner-tab-holder > .text .image.right {
  float: right;
  padding-left: 30px;
}
.inner-tab .inner-tab-holder > .text .image.full {
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .image.full img {
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .bkg-image {
  position: absolute;
  z-index: 1;
  right: 123px;
  top: 34px;
}
.inner-tab .inner-tab-holder > .text .heading {
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner-tab .inner-tab-holder > .text .heading .author {
  white-space: nowrap;
}
.inner-tab .inner-tab-holder > .text h3, .inner-tab .inner-tab-holder > .text .h3 {
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder > .text h4, .inner-tab .inner-tab-holder > .text .h4 {
  margin: 0 0 15px;
}
.inner-tab .inner-tab-holder > .text p.no-margin {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text p.no-margin:not(:last-of-type) {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text p:not(:last-of-type) {
  margin: 0 0 24px;
}
.inner-tab .inner-tab-holder > .text ul, .inner-tab .inner-tab-holder > .text ol {
  padding-left: 27px;
}
.inner-tab .inner-tab-holder > .text ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text ol li:not(:last-of-type) {
  margin: 0 0 10px;
}
.inner-tab .inner-tab-holder > .text > .action {
  display: inline-block;
  width: 100%;
  margin: 14px 0 0;
}
.inner-tab .inner-tab-holder > .text > .action.big-gap {
  margin: 25px 0 0;
}
.inner-tab .inner-tab-holder > .text > .action .btn {
  min-width: 240px;
  text-align: center;
}
.inner-tab .inner-tab-holder > .text .note {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: #F8F8F8;
  border-radius: 100px;
  color: #056256;
  font-size: 14px;
  line-height: 140%;
  margin: 26px 0 0;
}
.inner-tab .inner-tab-holder > .text .note.bigger-padding {
  padding: 15px;
}
.inner-tab .inner-tab-holder > .text .note.no-margin {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text .note .icon {
  width: 24px;
  height: 24px;
}
.inner-tab .inner-tab-holder > .text .note .icon svg {
  width: 100%;
  height: 100%;
}
.inner-tab .inner-tab-holder > .text .note .text {
  width: calc(100% - 24px);
  box-sizing: border-box;
  padding-left: 10px;
}
.inner-tab .inner-tab-holder > .text .text-group {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .text-group:not(:last-of-type) {
  margin: 0 0 40px;
}
.inner-tab .inner-tab-holder > .text .info-text {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .info-text p {
  color: #666666;
}
.inner-tab .inner-tab-holder > .text .info-text p:not(:last-of-type) {
  margin: 0 0 15px;
}
.inner-tab .inner-tab-holder > .text .info-text a {
  text-decoration: none;
}
.inner-tab .inner-tab-holder > .text .small-text {
  width: 100%;
  margin: 0 0 20px;
  color: #666666;
}
.inner-tab .inner-tab-holder > .text .small-text p:not(:last-of-type) {
  margin: 0 0 9px;
}
.inner-tab .inner-tab-holder > .text .small-text ul, .inner-tab .inner-tab-holder > .text .small-text ol {
  line-height: 140%;
  margin-top: 16px;
  margin-bottom: 21px;
}
.inner-tab .inner-tab-holder > .text .small-text ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text .small-text ol li:not(:last-of-type) {
  margin: 0 0 4px;
}
.inner-tab .inner-tab-holder .reviews-slider {
  width: 100%;
  padding-bottom: 45px;
  margin-bottom: 18px;
}
.inner-tab .inner-tab-holder .reviews-slider .nav ul.slick-dots {
  bottom: 0;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item {
  width: 100%;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder {
  width: 100%;
  height: 418px;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder .image {
  width: 100%;
  height: 100%;
  text-align: center;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder .image img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.inner-tab .inner-tab-holder .socials-links {
  display: flex;
  align-items: center;
  width: 100%;
}
.inner-tab .inner-tab-holder .socials-links .text {
  float: left;
  font-size: 16px;
  margin-right: 16px;
}
.inner-tab .inner-tab-holder .socials-links .links {
  float: left;
  margin: 0 -10px;
}
.inner-tab .inner-tab-holder .socials-links .links .link {
  float: left;
  margin: 0 10px;
}
.inner-tab .inner-tab-holder .socials-links .links .link a {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 44px;
  border-radius: 50%;
  background-color: #056256;
  color: white;
  text-align: center;
}
.inner-tab .inner-tab-holder .socials-links .links .link a:hover {
  background-color: #0ac3ab;
}
.inner-tab .inner-tab-holder .socials-links .links .link a svg {
  vertical-align: middle;
}
.inner-tab .inner-tab-holder .about-text {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 37px 40px 40px;
  background-color: white;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image {
  width: 298px;
  float: left;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image .image-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
  width: calc(100% - 298px);
  float: left;
  box-sizing: border-box;
  padding-left: 25px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder p {
  color: #666666;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
  display: inline-block;
  width: 100%;
  margin: 21px 0 0;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  margin: 0 0 17px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .position {
  display: inline-block;
  width: 100%;
  color: #666666;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
  display: inline-block;
  width: 100%;
  margin: 35px 0 0;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action .btn {
  min-width: 239px;
  text-align: center;
  padding: 9.5px 10px;
}

@media (max-width: 1279px) {
  .bulletin-tabs-block .bulletin-tabs-block-holder {
    padding: 23px 25px 25px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls {
    margin: 0 0 20px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
    margin: 0 4px 8px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    font-size: 14px;
    line-height: 130%;
    padding: 11px 15px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    padding: 10px 10px;
  }
  .inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
    padding: 30px 25px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 25px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
    margin: 0 0 32px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
    margin: 0 0 15px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 ul, .inner-tab .inner-tab-holder > .text.variant-2 ol {
    margin-top: 15px;
    padding-left: 25px;
    margin-bottom: 20px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text.variant-2 ol li:not(:last-of-type) {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder > .text .heading {
    margin: 0 0 29px;
  }
  .inner-tab .inner-tab-holder > .text p:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder > .text .note {
    font-size: 12px;
    margin: 17px 0 0;
  }
  .inner-tab .inner-tab-holder > .text > .action {
    margin: 10px 0 0;
  }
  .inner-tab .inner-tab-holder > .text > .action.big-gap {
    margin: 20px 0 0;
  }
  .inner-tab .inner-tab-holder > .text .text-group {
    display: inline-block;
    width: 100%;
  }
  .inner-tab .inner-tab-holder > .text .text-group:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .socials-links .text {
    font-size: 14px;
    margin-right: 10px;
  }
  .inner-tab .inner-tab-holder .socials-links .links {
    margin: 0 -5px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link {
    margin: 0 5px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link a {
    width: 40px;
    height: 40px;
    line-height: 37px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link a svg {
    width: 21px;
    height: auto;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
    padding-left: 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
    margin: 15px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
    margin: 0 0 15px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
    margin: 20px 0 0;
  }
}
@media (max-width: 767px) {
  .bulletin-tabs-block.xs-reverse {
    margin: -15px 0 0;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder {
    background-color: white;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls {
    margin: 0;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    background-color: #F8F8F8;
    border-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a.current {
    background-color: #056256;
    border-color: #056256;
    color: white;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 17px 19px 20px;
    background-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder > .text.variant-2 .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: white;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
    padding: 15px 5.175%;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder {
    width: 100%;
    margin: 0;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
    margin: 0 0 3px;
    width: 100%;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 10.5px 15px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs {
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs .tab {
    padding: 15px 5.175% 0;
    box-sizing: border-box;
  }
  .inner-tab .inner-tab-holder .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: white;
  }
  .inner-tab .inner-tab-holder .iconed-links-block {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 15px 25px 15px;
  }
  .inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder.slick-slider .slick-list {
    overflow: visible;
  }
  .inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder {
    height: 380px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder {
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .image {
    width: 100%;
    margin: 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
    margin: 15px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
    margin: 20px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action .btn {
    min-width: auto;
    width: 100%;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
    background-color: transparent;
    padding: 0;
    overflow: visible;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links {
    width: calc(100% + 5px);
    margin-bottom: -5px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: white;
    border-color: white;
    padding: 5px 15px;
    font-size: 14px;
    width: 100%;
  }
  .inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
    padding: 0;
    background-color: none;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 0;
    background-color: transparent;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
    margin: 0 0 30px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text {
    padding: 0;
    background-color: transparent;
  }
  .inner-tab .inner-tab-holder > .text .bkg-image {
    right: 50%;
    top: 34px;
    transform: translateX(50%);
  }
  .inner-tab .inner-tab-holder > .text .heading {
    flex-direction: column-reverse;
    align-items: start;
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder > .text .heading .heading-text h3, .inner-tab .inner-tab-holder > .text .heading .heading-text .h3 {
    line-height: 130%;
  }
  .inner-tab .inner-tab-holder > .text .heading .author {
    margin: 0 0 11px;
  }
  .inner-tab .inner-tab-holder > .text h4, .inner-tab .inner-tab-holder > .text .h4 {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text p {
    line-height: 22px;
  }
  .inner-tab .inner-tab-holder > .text .note {
    background-color: white;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    margin: 12px 0 0;
    border-radius: 10px;
  }
  .inner-tab .inner-tab-holder > .text .note .icon {
    width: 16px;
    height: 16px;
  }
  .inner-tab .inner-tab-holder > .text .note .text {
    width: calc(100% - 16px);
    box-sizing: border-box;
    padding-left: 5px;
  }
  .inner-tab .inner-tab-holder > .text > .action .btn {
    min-width: none;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .collapsabale-xs.open .collapsabale-container {
    max-height: 3000px;
    transition: all 1.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs.open .collapsabale-container:after {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs.open .collapse-action .open {
    opacity: 0;
    touch-action: none;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs.open .collapse-action .close {
    opacity: 1;
    touch-action: auto;
    pointer-events: all;
    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs .collapsabale-container {
    max-height: 420px;
    overflow: hidden;
    position: relative;
    transition: all 1.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs .collapsabale-container:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 93px;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }
  .collapsabale-xs .collapse-action {
    width: 100%;
    height: 18px;
    text-align: center;
    position: relative;
    margin: 8px 0 0;
  }
  .collapsabale-xs .collapse-action .open, .collapsabale-xs .collapse-action .close {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
    touch-action: none;
    pointer-events: none;
  }
  .collapsabale-xs .collapse-action .open {
    opacity: 1;
    touch-action: auto;
    pointer-events: all;
  }
  .collapsabale-xs .collapse-action .close .icon svg {
    transform: rotate(180deg);
  }
  .collapsabale-xs .collapse-action a {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: #1D1D1B;
  }
  .collapsabale-xs .collapse-action a .text {
    float: left;
  }
  .collapsabale-xs .collapse-action a .icon {
    float: left;
    margin-left: 5px;
    width: 6px;
    height: 11px;
  }
  .collapsabale-xs .collapse-action a .icon svg {
    width: 100%;
    height: 100%;
  }
}
.common-section-header {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-section-header .common-section-header-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-section-header .common-section-header-holder .action a {
  display: flex;
  align-items: center;
  color: #1D1D1B;
}
.common-section-header .common-section-header-holder .action a:hover {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.common-section-header .common-section-header-holder .action a:hover .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.common-section-header .common-section-header-holder .action a .icon {
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #1D1D1B;
}
.common-section-header .common-section-header-holder .action a .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .common-section-header .common-section-header-holder .action a {
    font-size: 16px;
    line-height: 22px;
  }
  .common-section-header .common-section-header-holder .action a .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.iconed-links-block {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
  width: 70.5%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
  width: 29.5%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
  width: calc(50% - 160px);
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
  width: 320px;
}
.iconed-links-block .iconed-links-block-holder .link-item.reverse .link-item-holder .link {
  background-color: white;
}
.iconed-links-block .iconed-links-block-holder .link-item {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image {
  flex-direction: row;
  align-items: center;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image {
  width: 115px;
  height: 115px;
  flex-grow: 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image .image-holder {
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image .image-holder svg {
  width: 100%;
  height: auto;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image > .text {
  width: calc(100% - 150px);
  box-sizing: border-box;
  padding-left: 30px;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .heading {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 28px 30px 30px;
  color: #1D1D1B;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image .image-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image .image-holder svg {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .heading {
  display: inline-block;
  width: 100%;
  color: #1D1D1B;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
  display: inline-block;
  width: 100%;
  color: #666666;
  font-size: 14px;
  line-height: 140%;
  margin: 11px 0 0;
  color: #666666;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc.lines-2 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
  display: inline-block;
  width: 100%;
  margin: 29px 0 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 9.5px 15px;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
  display: inline-flex;
  align-items: center;
  width: 100%;
  color: #666666;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  margin: 20px 0 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .text {
  float: left;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon {
  float: left;
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1470px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
    width: 62%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
    width: 38%;
  }
}
@media (max-width: 1279px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
    width: 33.333%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
    width: 33.333%;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image {
    flex-direction: column;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image {
    width: 100%;
    height: auto;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image > .text {
    width: 100%;
    padding: 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 16px 25px 16px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image {
    min-height: 222px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
    font-size: 12px;
    margin: 13px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
    margin: 15px 0 12px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
    margin: 13px 0 0;
  }
}
@media (max-width: 767px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 15px 20px 17px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
    font-size: 12px;
    line-height: 17px;
    margin: 7px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc.lines-2 {
    height: auto;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
    margin: 15px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action .btn {
    padding: 9.5px 15px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
    font-size: 14px;
    margin: 18px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.news-list-preview {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder > .action {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder > .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
}
.news-list-preview .news-list-preview-holder > .action .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}

.news-slider {
  display: inline-block;
  width: 100%;
}
.news-plain-list {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: -30px;
}
.news-plain-list .news-item {
  display: block;
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 30px;
}

.news-item.reverse .news-item-holder a .desc {
  background-color: #F8F8F8;
}

.news-item {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  color: #1D1D1B;
}
.news-item .news-item-holder a:hover .image img {
  transform: scale(1.1);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a:hover .desc .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a:hover .desc .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .image {
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.news-item .news-item-holder a .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 27px 30px 30px;
}
.news-item .news-item-holder a .desc .date {
  display: inline-block;
  width: 100%;
  margin: 0 0 13px;
  font-size: 14px;
}
.news-item .news-item-holder a .desc .name {
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}
.news-item .news-item-holder a .desc .action {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 24px 0 0;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc .action .text {
  font-size: 14px;
}
.news-item .news-item-holder a .desc .action .icon {
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1470px) {
  .news-item .news-item-holder a .image {
    height: 240px;
  }
}
@media (max-width: 1279px) {
  .news-plain-list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -20px;
  }
  .news-plain-list .news-item {
    padding: 0 10px;
    margin: 0 0 20px;
  }
  .news-plain-list .news-item .news-item-holder a .desc {
    padding: 15px 20px 23px;
  }
  .news-item .news-item-holder a .image {
    height: 190px;
  }
  .news-item .news-item-holder a .desc {
    padding: 16px 25px 25px;
  }
  .news-item .news-item-holder a .desc .date {
    font-size: 10px;
    margin: 0 0 11px;
  }
  .news-item .news-item-holder a .desc .name {
    height: 36px;
    font-size: 14px;
    line-height: 130%;
    font-weight: 400;
  }
  .news-item .news-item-holder a .desc .action {
    margin: 17px 0 0;
  }
  .news-item .news-item-holder a .desc .action .text {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .news-list-preview {
    overflow: hidden;
  }
  .news-list-preview .news-list-preview-holder > .action {
    margin: 6px 0 0;
  }
  .news-plain-list {
    width: 100%;
    margin-left: 0;
    margin-bottom: -15px;
  }
  .news-plain-list .news-item {
    width: 100%;
    padding: 0;
    margin: 0 0 15px;
  }
  .news-plain-list .news-item .news-item-holder a .image {
    height: 160px;
  }
  .news-plain-list .news-item .news-item-holder a .desc {
    padding: 11px 20px 16px;
  }
  .news-plain-list .news-item .news-item-holder a .desc .action {
    margin: 18px 0 0;
  }
  .news-slider .slick-slider .slick-list {
    overflow: visible;
    box-sizing: border-box;
    padding: 0 6.5% 0 0;
  }
  .news-item .news-item-holder a .image {
    height: 154px;
  }
  .news-item .news-item-holder a .desc {
    padding: 12px 20px 12px;
  }
  .news-item .news-item-holder a .desc .date {
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 6px;
  }
  .news-item .news-item-holder a .desc .name {
    font-size: 16px;
    line-height: 22px;
    height: 44px;
  }
  .news-item .news-item-holder a .desc .action {
    margin: 24px 0 0;
  }
  .news-item .news-item-holder a .desc .action .text {
    font-size: 14px;
  }
  .news-item .news-item-holder a .desc .action .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.plaque-links-block {
  display: inline-block;
  width: 100%;
}
.plaque-links-block .plaque-links-block-holder {
  display: inline-block;
  width: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
  font-size: 18px;
  font-weight: bold;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .name {
  margin: 0 0 12px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
  font-size: 16px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 27px 30px 30px;
  font-size: 16px;
  line-height: 140%;
  color: #1D1D1B;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a:hover .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a:hover .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  margin: 0 0 20px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
  min-height: 44px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
  display: flex;
  align-items: center;
  width: 100%;
  color: #666666;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .text {
  float: left;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon {
  float: left;
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1279px) {
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
    font-size: 16px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    font-size: 14px;
    line-height: 130%;
    padding: 17px 25px 20px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
    margin: 0 0 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
    min-height: 39px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .plaque-links-block.xs-bigger-plaque .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    padding: 17px 20px 20px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
    font-size: 16px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    font-size: 16px;
    line-height: 22px;
    padding: 13px 20px 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
    margin: 0 0 10px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
    min-height: 32px;
    margin: 0 0 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon {
    width: 11px;
    height: 6px;
    margin-left: 5px;
  }
}