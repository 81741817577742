#links {
    .links-section {
        .iconed-links-block {
            margin: 0 0 42px;
        }
    }
}

@import "../../media/tablet/includes/index/index_links_section";
@import "../../media/mobile/includes/index/index_links_section";
