@media (max-width: $screen-sm) {
    // sm-view
    .about-text { 
        .about-text-holder { 
            h2, .h2 { 

            }

            .image { 
                margin: 0 0 18px;
                .image-holder { 
                    img {

                    }

                    .desc {
                        font-size: 12px;
                        line-height: 17px;
                        padding: 11px 28px 10px 15px;
                    }
                }
            }

            .text { 
                &.col-2 {
                    column-count: 1;
                    column-gap: 0px;
                }

                p {
                    line-height: 20px;
                }
            }

            .action {
                margin: 23px 0 0;
                .btn {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .articles-list-block { 
        .articles-list-block-holder { 
            width: 110.35%;
            margin-left: -5.175%;
            padding: 35px 5.175% 20px;
            margin-bottom: -4px;
            .articles-list {
                .articles-list-holder {
                    margin-bottom: -7.5px;
                    .article-link-preview { 
                        margin: 0 0 7.5px;
                        .article-link-preview-holder {
                            .article-link { 
                                padding: 16px 20px 15px;
                                .desc {
                                    margin: 0 0 7px;
                                    .important { 
                                        font-size: 12px;
                                        line-height: 12px;
                                        padding: 4.5px 7px;
                                    }

                                    .date {
                                        font-size: 12px;
                                        line-height: 12px;
                                    }
                                }

                                .name {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
