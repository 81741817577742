#index-bulletin {
    .bulletin-section {
        .bulletin-section-holder {
            .plaque-links-block {
                margin: 0 0 30px;
            }
        }
    }
}

@import "../../media/tablet/includes/index/index_bulletin";
@import "../../media/mobile/includes/index/index_bulletin";
