.about-section {
    display: inline-block;
    width: 100%;
    .about-section-holder {
        display: inline-block;
        width: 100%;
    }
}

.about-text { 
    display: inline-block;
    width: 100%;
	.about-text-holder { 
        display: inline-block;
        width: 100%;
        overflow: hidden;
        background-color: white;
        box-sizing: border-box;
        padding: 40px;
		h2, .h2 { 
            margin: 0 0 30px;
		}

		.image { 
            display: inline-block;
            width: 100%;
            margin: 0 0 25px;
			.image-holder { 
                display: inline-block;
                width: 100%;
                overflow: hidden;
                position: relative;
				img { 
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    z-index: 2;
				}

				.desc {     
                    position: absolute;
                    z-index: 3;
                    right: -1px;
                    top: -1px;
                    font-size: 14px;
                    line-height: 20px;
                    background-color: $bg-secondary;
                    padding: 18px 20px 21px;
                    overflow: hidden;
                    border-radius: 0 0 0 20px;
					br { 

					}
				}
			}
		}

		.text { 
            display: inline-block;
            width: 100%;
            text-align: justify;
            &.col-2 {
                column-count: 2;
                column-gap: 40px;
            }

			p { 

			}

			ul { 

				li { 
                    &:not(:last-of-type) {
                        margin: 0 0 15px;
                    }
				}
			}
		}

		.action { 
            display: inline-block;
            width: 100%;
            margin: 26px 0 0;
			.btn { 

			}
		}
	}
}

.articles-list-block { 
    display: inline-block;
    width: 100%;
	.articles-list-block-holder { 
        display: inline-block;
        width: 100%;
        overflow: hidden;
        background-color: white;
        box-sizing: border-box;
        padding: 30px;
		.common-section-header { 

			.common-section-header-holder { 

				.text { 

					.accent-font { 

					}
				}

				.action { 

					a { 

						.text { 

						}

						.icon { 

							svg { 

								path { 

								}
							}
						}
					}
				}
			}
		}

		.articles-list { 
            display: inline-block;
            width: 100%;
			.articles-list-holder { 
                display: inline-block;
                width: 100%;
                margin-bottom: -9.5px;
				.article-link-preview { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 9.5px;
					.article-link-preview-holder { 
                        display: inline-block;
                        width: 100%;
						.article-link { 
                            display: inline-block;
                            width: 100%;
                            background-color: $bg-secondary;
                            color: $gray-base;
                            overflow: hidden;
                            box-sizing: border-box;
                            padding: 30px 30px 30px;
                            &:hover {   
                                .name {
                                    color: $brand-primary;
                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                }
                            }
							.desc { 
                                display: flex;
                                align-items: center;
                                height: 26px;
                                margin: 0 0 10px;
								.important { 
                                    margin-right: 10px;
                                    background-color: $brand-primary;
                                    color: white;
                                    font-size: 12px;
                                    line-height: 12px;
                                    padding: 7px 7px;   
                                    border-radius: 100px;
								}

								.date { 
                                    font-size: 14px;
                                    line-height: 14px;
								}
							}

							.name {     
                                display: inline-block;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -moz-box;
                                -moz-box-orient: vertical;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                line-clamp: 3;
                                box-orient: vertical;
                                font-weight: 400;
                                color: $gray-base;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/about_section";
@import "../../media/mobile/includes/index/about_section";
