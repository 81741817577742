@media (max-width: $screen-lg) {
    // lg-md view
    .main-banner-section {
        height: 690px;
        .main-banner-section-holder { 
            .main-banner-bkg-slider { 

            }

            .main-banner-content-slider-block { 
                .main-banner-content-slider-block-holder { 
                    .main-banner-content-slider { 
                        .prev, .next { 
                            left: -15px;
                            background-color: white;
                            color: $brand-primary;
                            &:hover {
                                background-color: $brand-primary;
                                border-color: $brand-primary;
                                color: white;
                            }
                        }

                        .next { 
                            left: auto;
                            right: -15px;
                        }

                        .main-banner-content-slider-holder { 
                            .main-banner-content-slide { 
                                .main-banner-content-slide-holder { 
                                    .text { 
                                        width: calc(100% - 460px);
                                    }

                                    .image { 
                                        width: 460px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .main-banner-section {
        height: 490px;
        .main-banner-section-holder {
            .main-banner-bkg-slider { 

            }

            .main-banner-content-slider-block { 
                .main-banner-content-slider-block-holder { 
                    .main-banner-content-slider { 

                        .nav {
                            ul.slick-dots {
                                bottom: 9px;
                            }
                        }

                        .main-banner-content-slider-holder { 
                            .main-banner-content-slide { 
                                padding: 85px 0 45px;
                                .main-banner-content-slide-holder { 
                                    padding: 30px;
                                    .text {
                                        width: calc(100% - 332px);
                                        padding: 0;
                                        .text-holder { 
                                            width: 94%;
                                            .desc { 
                                                .heading {
                                                    margin: 0 0 14px;
                                                }

                                                p {
                                                    font-size: 12px;
                                                    line-height: 140%;
                                                }
                                            }

                                            .action {
                                                margin: 7px 0 0;
                                            }
                                        }
                                    }

                                    .image { 
                                        width: 332px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
