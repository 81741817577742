@media (max-width: $screen-sm) {
    // sm-view
    #links {
        .links-section {
            .iconed-links-block {
                margin: 0 0 31px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
