.main-banner-section { 
    display: inline-block;
    width: 100%;
    height: 800px;
	.main-banner-section-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
		.main-banner-bkg-slider { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
			.main-banner-bkg-slider-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				.main-banner-img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
					.main-banner-img-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
						img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
						}
					}
				}
			}
		}

		.main-banner-content-slider-block { 
            display: inline-block;
            width: 100%;
            height: 100%;
            .wrapper-inside {
                height: 100%;
            }
			.main-banner-content-slider-block-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                .main-banner-content-slider { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    .prev, .next { 
                        left: -60px;
                        transform: translateY(calc(-50% + 28px));
                        svg { 

                        }
                    }

                    .next { 
                        left: auto;
                        right: -60px;
                        svg { 

                        }
                    }

                    .nav { 
                        ul.slick-dots {
                            bottom: 30px;
                        }
                    }

                    .main-banner-content-slider-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        .main-banner-content-slide { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            box-sizing: border-box;
                            padding: 150px 0 94px;
                            .main-banner-content-slide-holder { 
                                display: flex;
                                width: 100%;
                                height: 100%;
                                border: 1px solid white;
                                box-sizing: border-box;
                                padding: 40px;
                                position: relative;
                                overflow: hidden;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    background-color: rgba(255,255,255,0.15);
                                    z-index: 1;
                                    backdrop-filter: blur(5px);
                                    -webkit-backdrop-filter: blur(5px);
                                }
                                .text { 
                                    width: calc(100% - 660px);
                                    height: auto;
                                    display: flex;
                                    align-items: center;
                                    padding: 0 19px 0;
                                    box-sizing: border-box;
                                    position: relative;
                                    z-index: 2;
                                    .text-holder { 
                                        display: inline-block;
                                        width: 80%;
                                        .desc { 
                                            display: inline-block;
                                            width: 100%;
                                            color: white;
                                            .heading { 
                                                margin: 0 0 19px;
                                                display: inline-block;
                                                br { 

                                                }
                                            }

                                            .inner-text {
                                                display: inline-block;
                                                width: 100%;
                                                p { 

                                                }
                                            }
                                        }

                                        .action { 
                                            display: inline-block;
                                            width: 100%;
                                            margin: 27px 0 0;
                                            .btn { 

                                            }
                                        }
                                    }
                                }

                                .image { 
                                    width: 660px;
                                    height: auto;
                                    position: relative;
                                    z-index: 2;
                                    .image-holder { 
                                        display: inline-block;
                                        width: 100%;
                                        height: 100%;
                                        img { 
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: top;
                                            overflow: hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
	}
}

@import "../../media/tablet/includes/index/main_banner";
@import "../../media/mobile/includes/index/main_banner";
