@media (max-width: $screen-sm) {
    .collapsabale-xs {
        &.open {
            .collapsabale-container {
                max-height: 3000px;
                transition: all 1.3s cubic-bezier(0.45, 0, 0.55, 1);
                &:after {
                    opacity: 0;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }
            }
            .collapse-action {
                .open {
                    opacity: 0;
                    touch-action: none;
                    pointer-events: none;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }

                .close {
                    opacity: 1;
                    touch-action: auto;
                    pointer-events: all;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                }
            }
        }
        .collapsabale-container {
            max-height: 420px;
            overflow: hidden;
            position: relative;
            transition: all 1.3s cubic-bezier(0.45, 0, 0.55, 1);
            &:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 93px;
                background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 100%);
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
            }
        }
        .collapse-action {
            width: 100%;
            height: 18px;
            text-align: center;
            position: relative;
            margin: 8px 0 0;
            .open, .close {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                opacity: 0;
                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                touch-action: none;
                pointer-events: none;
            }
            .open {
                opacity: 1;
                touch-action: auto;
                pointer-events: all;
            }
            .close {
                .icon {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
            a {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                line-height: 22px;
                color: $gray-base;
                .text {
                    float: left;
                }

                .icon {
                    float: left;
                    margin-left: 5px;
                    width: 6px;
                    height: 11px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}