@media (max-width: $screen-sm) {
    // sm-view
    #index-bulletin {
        .bulletin-section {
            .bulletin-section-holder {
                .plaque-links-block {
                    margin: 0 0 30px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
